import React from "react";
import _ from "lodash";

import { Layout } from "../components/index";

import HeroTitle from "../components/HeroTitle";

export default class Pricing extends React.Component {
  render() {
    return (
      <Layout {...this.props}>
        <section className="page">
          <div className="p-strip is-deep">
            <HeroTitle {...this.props} />

            <div className="u-pre">
              {_.get(this.props, "pageContext.site.data.pricing.price-intro")}
            </div>

            <table>
              <thead>
                <tr>
                  <th className="u-align--center">Treatment(s)</th>
                  <th className="u-align--center">Duration</th>
                  <th className="u-align--center">Price</th>
                </tr>
              </thead>
              <tbody>
                {_.map(
                  _.get(this.props, "pageContext.site.data.pricing.prices"),
                  (line_item, line_item_idx) => (
                    <tr key={line_item_idx} className="">
                      <td className="u-align--center">
                        {_.get(line_item, "quantity")} treatment
                      </td>
                      <td className="u-align--center">
                        {_.get(line_item, "duration")} mins
                      </td>
                      <td className="u-align--center">
                        £{_.get(line_item, "price")}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </section>
      </Layout>
    );
  }
}
